import {
  PDF_REPORTS_EXT_POINT,
  type TPDFReportTypeExtensionPoint
} from "@ui-platform/ui-core-services-extension-points";
// import { getFrameworkVars } from "@sparky/framework";

export default {
  point: PDF_REPORTS_EXT_POINT,
  configs: [
    {
      id: "usage",
      config: {
        name: "Prisma Access",
        report_type: "usage",
        category: "summary",
        description:
          "See how you’re leveraging what’s available to you with Prisma Access, and get a high-level view into the health and performance of your environment.",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/usage" : "/dashboards/usage",
        version: 0,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => false // Boolean(state.reporting?.reportsById?.prisma_access_report?.allowed)
      }
    },
    {
      id: "bpa",
      config: {
        name: "Best Practices",
        report_type: "bpa",
        category: "posture",
        description:
          "Best practices include checks for the Center for Internet Security’s Critical Security Controls (CSC).",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/bpa" : "/dashboards/bpa",
        version: 0,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => false // Boolean(state.reporting?.reportsById?.best_practices_report?.allowed)
      }
    },
    {
      id: "bpa_di",
      config: {
        name: "Best Practices",
        report_type: "bpa_di",
        category: "posture",
        description:
          "Best practices include checks for the Center for Internet Security’s Critical Security Controls (CSC).",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/bpa" : "/dashboards/bpa",
        version: 0,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: false
      }
    },
    {
      id: "app_usage_report",
      config: {
        name: "App Usage Report",
        report_type: "app_usage_report",
        category: "Network Activity",
        description:
          "Know the security challenges associated with the applications traversing your network. Key findings here help you to refine security rules for unsanctioned and risky applications.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/app_usage"
        //     : "/dashboards/app_usage",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => Boolean(state.reporting?.reportsById?.app_usage_report?.allowed)
      }
    },
    {
      id: "user_activity_report",
      config: {
        name: "User Activity",
        report_type: "user_activity_report",
        category: "Network Activity",
        description:
          "Get visibility into an individual users’ browsing patterns: their most frequently visited sites, the sites with which they’re transferring data, and attempts to access high-risk sites.",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/uar" : "/dashboards/uar",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.user_activity_report?.allowed)
      }
    },
    {
      id: "executive_report",
      config: {
        name: "Executive Summary",
        report_type: "executive_report",
        category: "summary",
        description:
          "See how your security subscriptions are protecting you. Peer data gives you perspective on your industry’s threat landscape and ━ for subscriptions you’re not using ━ shows you where you might be exposed to threats.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/executive"
        //     : "/dashboards/executive",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => Boolean(state.reporting?.reportsById?.executive_report?.allowed)
      }
    },
    {
      id: "network_activity_report",
      config: {
        name: "Network Activity",
        report_type: "network_activity_report",
        category: "activity",
        description:
          "Get a view into the different sources generating traffic in your network, and drill down to investigate any unexpected findings.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/network_activity"
        //     : "/dashboards/network_activity",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.network_activity_report?.allowed)
      }
    },
    {
      id: "wildfire_report",
      config: {
        name: "WildFire",
        report_type: "wildfire_report",
        category: "security",
        description:
          "WildFire protects against net new malware that’s concealed in files, executables, and email links.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/wildfire"
        //     : "/dashboards/wildfire",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => Boolean(state.reporting?.reportsById?.wildfire_report?.allowed)
      }
    },
    {
      id: "dns_report",
      config: {
        name: "DNS Security",
        report_type: "dns_report",
        category: "security",
        description:
          "DNS Security protects your network from advanced threats that use DNS. Without DNS Security in place, malware might have infiltrated your network through malicious DNS requests and remained unnoticed.",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/dns" : "/dashboards/dns",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => Boolean(state.reporting?.reportsById?.dns_report?.allowed)
      }
    },
    {
      id: "advanced_threat_prevention_report",
      config: {
        name: "Advanced Threat Prevention",
        report_type: "advanced_threat_prevention_report",
        category: "security",
        description:
          "Examine the threats detected on your network and identify opportunities to strengthen your security posture.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/advanced_threat_prevention"
        //     : "/dashboards/advanced_threat_prevention",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.advanced_threat_prevention_report?.allowed)
      }
    },
    {
      id: "advanced_url_filtering_report",
      config: {
        name: "Advanced URL Filtering",
        report_type: "advanced_url_filtering_report",
        category: "security",
        description:
          "Get a high-level view of the different types of activities taking place in your network recently.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/advanced_url_filtering"
        //     : "/dashboards/advanced_url_filtering",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.advanced_url_filtering_report?.allowed)
      }
    },
    {
      id: "executive_report_dlp_details_report",
      config: {
        name: "DLP",
        report_type: "executive_report_dlp_details_report",
        category: "activity",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/dlp_details"
        //     : "/dashboards/dlp_details",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.executive_report_dlp_details_report?.allowed)
      }
    },
    {
      id: "unified_network_activity_report",
      config: {
        name: "Activity Insights - Summary",
        report_type: "unified_network_activity_report",
        category: "Network Activity",
        description:
          "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/insights/activity_insights/summary"
        //     : "/insights/activity_insights/summary",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.prisma_access_report?.allowed)
      }
    }
    // {
    //   id: "vnr.executive",
    //   config: {
    //     name: "Executive Summary",
    //     report_type: "vnr.executive",
    //     category: "summary",
    //     link: () => getFrameworkVars().aiops_free ? "/aiops-free/dashboards/executive" : "/dashboards/executive",
    //     isAllowed: (state: any) => Boolean(state.reporting?.reportsById?.executive_report?.allowed),
    //     temporary: true,
    //     version: 2
    //   }
    // },
    // {
    //   id: "vnr.activity_insights",
    //   config: {
    //     name: "Activity Insights - Summary",
    //     report_type: "vnr.activity_insights",
    //     category: "summary",
    //     link: () => getFrameworkVars().aiops_free ? "/aiops-free"/insights/activity_insights/summay""  "/insights/activity_insights/summary",
    //     isAllowed: (state: any) =>
    //       Boolean(state.reporting?.reportsById?.unified_network_activity_report?.allowed),
    //     temporary: true,
    //     version: 2
    //   }
    // }
  ]
} satisfies TPDFReportTypeExtensionPoint;
